import React, { useState, useRef, useEffect, useContext } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, TextInput, Button, Image, StyleSheet, View, TouchableOpacity } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { Camera } from 'expo-camera';

const UserScreen = (props) => {

    const [user, setUser] = useContext(Context);
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);

    const [hasPermission, setHasPermission] = useState(null);

    const isFocused = useIsFocused();

    const ref = useRef(null);
    const camera = useRef(null);

    useEffect(() => {

        const fetchData = async () => {

            let response = await fetch('http://seansusko.com/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUser',
                    id: userId
                })
            });

            const results = await response.json();
            setUser(results.user);
            
        };

        if(userId){
            fetchData()
        }

    }, [userId, updateCount]);

    const signIn = () => {
        const fetchData = async (email) => {

            let response = await fetch('http://seansusko.com/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUserByEmail',
                    email: email
                })
            });

            const results = await response.json();
            if(!results.success){
                setError(results.message)
            } else {
                setUserId(results.user.id);
            }
            
        };

        const email = ref.current.value;
        if(!email){
            setError("Really? You're just going to leave the email field blank? email:" + email)
        } else {
            fetchData(email)
        }
        
    }

    const styles = StyleSheet.create({
        header:{
            backgroundColor: "#169B62",
            height:200,
        },
        camera: {
            flex: 1,
        },
        avatar: {
            width: 130,
            height: 130,
            borderRadius: 63,
            borderWidth: 4,
            borderColor: "white",
            marginBottom:10,
            alignSelf:'center',
            position: 'absolute',
            marginTop:130
        },
        name:{
            fontSize:22,
            color:"#FFFFFF",
            fontWeight:'600',
        },
        body:{
            marginTop:40,
        },
        bodyContent: {
            flex: 1,
            alignItems: 'center',
            padding:30,
        },
        name:{
            fontSize:28,
            color: "#696969",
            fontWeight: "600"
        },
        buttonContainer: {
            marginTop:10,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
    });

    const logOut = () => {
        setUser({
            id: null,
            name: null,
            email: null,
            img_src: null,
            admin: 0
          })
    }

    useEffect(() => {

        const fetchData = async () => {

            let response = await fetch('http://seansusko.com/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUser',
                    id: userId
                })
            });

            const results = await response.json();
            setUser(results.user);
            
        };

        if(userId){
            fetchData()
        }

    }, [userId]);

    const getCameraPermission = () => {

        (async () => {
            const { status } = await Camera.requestCameraPermissionsAsync();
            alert(status);
            setHasPermission(status === 'granted');
          })();
        
    }

    const takeSelfie = async() => {

        setIsUploading(true);

        const options = {quality: 0.5};
        const photo = await camera.current.takePictureAsync(options);
      
        let localUri = photo.uri;
      
        let response = await fetch('http://seansusko.com/api/', {
            method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'updatePhoto',
                    id: user.id,
                    image: localUri
                })
        });

        const results = await response.json();
        setIsUploading(false);
        setHasPermission(false);
        setUpdateCount(updateCount + 1)

    }
      

    return (
        hasPermission ?
        <SafeAreaView style={{ flex: 1 }}>
            <Camera ref={camera} style={styles.camera} type={Camera.Constants.Type.front}>
                <View style={styles.buttonContainer}>
                    {isUploading ? 
                    <Text style={{color: '#fff'}}>Uploading Hideous Image ...</Text> :
                    <TouchableOpacity
                        style={styles.buttonContainer}
                        onPress={takeSelfie}>
                            <Text style={{color: '#fff'}}>Take Selfie</Text>
                    </TouchableOpacity>}
                </View>
            </Camera>
        </SafeAreaView> :
        user.id ? 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <View style={styles.container}>
                <View style={styles.header}></View>
                <Image style={styles.avatar} source={{uri: user.img_src.concat('?=update=' + updateCount)}}/>
                <View style={styles.body}>
                    <View style={styles.bodyContent}>
                    <Text style={styles.name}>{ user.name }</Text>
                        <TouchableOpacity style={styles.buttonContainer} onPress={getCameraPermission}>
                            <Text style={{color: 'white'}}>Update Image</Text>  
                        </TouchableOpacity>              
                        <TouchableOpacity style={styles.buttonContainer} onPress={logOut}>
                            <Text style={{color: 'white'}}>Log Out</Text> 
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </SafeAreaView>
         : 
         <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Image
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 200,
                    height: 200
                }}
                source={{
                uri: 'http://seansusko.com/rings.png',
            }}
            />
            <Text style={{ width: '80%', color: 'red'}}>{ error }</Text>
            <TextInput 
                ref={ref} 
                style={{
                    fontSize: 17,
                    backgroundColor: 'white',
                    marginBottom: 10,
                    marginTop: 40,
                    borderRadius: 10,
                    width: '80%',
                    padding: 9
                }}
                keyboardType="email-address"
                returnKeyType="done"
                returnKeyLabel="done"
                placeholder="Email" 
                autoCapitalize="none"
            />
            <TouchableOpacity style={styles.buttonContainer} onPress={signIn}>
                <Text style={{color: 'white'}}>Sign In</Text> 
            </TouchableOpacity>
        </SafeAreaView>
    );
}
export default UserScreen;
