import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, View, TouchableOpacity, StyleSheet, FlatList, RefreshControl } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import Loading from '../elements/Loading';

const ScoresScreen = (props) => {

    const [user] = useContext(Context);
    const isFocused = useIsFocused();

    const [stats, setStats] = useState(null);
    const [updates, setUpdates] = useState(0);
    const [place, setPlace] = useState(null);
    const [refreshing, setRefreshing] = useState(false);

    const fetchData = async () => {

        let response = await fetch('http://seansusko.com/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getStats'
            })
        });

        const results = await response.json();
        setStats(results.scores);
        var userData = results.scores.filter(obj => {
            return parseInt(obj.id) === user.id;
        })
        if(userData && userData[0]){
            setPlace(userData[0].place_alt)
        }
        setRefreshing(false);   
        
    };

    const openPlayerSchedule = (event) => {
        props.navigation.navigate('Player Schedule', { event: event })
    }

    const renderEvent = (stat) => {

        let iconColor;
        switch(stat.item.place){
            case 1: iconColor = "gold"; break;
            case 2: iconColor = "#C0C0C0"; break;
            case 3: iconColor = "#cd7f32"; break;
            default: iconColor = "lightgrey";
        }

        return (

            <TouchableOpacity style={[styles.card, {borderColor: iconColor}]}  onPress={e => openPlayerSchedule(stat.item.id)}>
                <Text><FontAwesome5 name="medal" size={25} color={iconColor} /> {stat.item.place_alt}</Text>
              
                <View style={styles.cardContent}>
                    <Text style={[styles.description, { color: "grey"}]}>{stat.item.name}</Text>
                    <Text style={styles.teammate}>Games Played: {stat.item.played}</Text>
                    <Text style={styles.teammate}>Points: {stat.item.points}</Text>
                </View>

                <Text style={{fontSize: 18, fontWeight: 'bold'}}> {stat.item.score} </Text>
            </TouchableOpacity>

        )
    }


    useEffect(() => {
        
        setTimeout(()=>{
            fetchData()
        }, 1000);

        return () => {
            setStats(null);
            setUpdates(0);
        }

    }, [isFocused, updates]);

    const refeshData = () => {
        setRefreshing(true);
        fetchData();
    };

    const styles = StyleSheet.create({
        tasks:{
          flex:1,
          width: '100%'
        },
        cardContent: {
          marginLeft:20,
          marginTop:10,
        },
        image:{
          width:25,
          height:25,
        },      
        card:{
          shadowColor: '#00000021',
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.37,
          shadowRadius: 7.49,
          elevation: 12,   
          minHeight: 120,   
          marginVertical: 10,
          marginHorizontal:20,
          backgroundColor:"white",
          flexBasis: '46%',
          padding: 10,
          flexDirection:'row',
          flexWrap: 'wrap',
          borderLeftWidth:6,
          flex: 1,
          justifyContent: 'space-between'
        },      
        description:{
          fontSize:20,
          flex:1,
          color:"#008080",
          fontWeight:'bold',
        },
        teammate:{
          fontSize:18,
          flex:1,
          color:"#696969",
          marginTop:5
        },
      }); 

    return (
        !stats ? 
        <Loading /> :         
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>  
            <View style={{ height: 40}}>
                <Text style={{
                    fontSize:19,
                    color:"#151515",
                    fontWeight: 'bold'
                }}>
                    Your Place: {place}
                    <TouchableOpacity onPress={refeshData} >
                        <FontAwesome name="refresh" size={30} color="#646464" style={{ marginLeft: 20, marginTop: 5}} />
                    </TouchableOpacity>
                </Text>                
            </View>    
            
            <FlatList 
                style={styles.tasks}
                data={stats}
                keyExtractor= {(stat) => stat.id}
                renderItem={renderEvent}
                refreshControl={<RefreshControl refreshing={refreshing} onRefesh={refeshData} />}
            />
        </SafeAreaView>
    );
}
export default ScoresScreen;
