import React, { useState, createContext } from "react";
import { TouchableNativeFeedbackBase } from "react-native";

// Create Context Object
export const Context = createContext();

// Create a provider for components to consume and subscribe to changes
export const Provider = props => {
  // const initUser = {
  //   id: null,
  //   name: null,
  //   email: null,
  //   img_src: null,
  //   admin: 0
  // }
  const initUser = {
    id: 1,
    name: 'Sean Susko',
    email: 'sean.susk.com',
    img_src: 'http://seansusko.com/api/user_img/1.jpg',
    admin: 1
  }
  const [user, setUser] = useState(initUser);

  return (
    <Context.Provider value={[user, setUser]}>
      {props.children}
    </Context.Provider>
  );
};