import React, { useContext } from 'react';
import { Text, SafeAreaView } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';

import UserScreen from './pages/UserScreen';
import ScheduleScreen from './pages/ScheduleScreen';
import ScheduleDetailsScreen from './pages/ScheduleDetailsScreen';
import ScoresScreen from './pages/ScoresScreen';
import HeatScreen from './pages/HeatScreen';

import { Context } from './Context';

const Tab = createBottomTabNavigator();

const ScheduleStack = createStackNavigator();

function UserStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Me" component={UserScreen} />
    </ScheduleStack.Navigator>
  );
}

function ScheduleStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Schedule" component={ScheduleScreen} />
      <ScheduleStack.Screen name="Details" component={ScheduleDetailsScreen} />
    </ScheduleStack.Navigator>
  );
}

function ScoresStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Rankings" component={ScoresScreen} />
    </ScheduleStack.Navigator>
  );
}

function HeatStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Current Heat" component={HeatScreen} />
    </ScheduleStack.Navigator>
  );
}

export default function Main() {

  const [user] = useContext(Context);

  return (
      <NavigationContainer>
        <Tab.Navigator
          screenOptions={({ route }) => ({
            tabBarIcon: ({ focused, color, size }) => {
              let iconName;

              switch(route.name){
                case 'Me': iconName = focused ? 'user' : 'user-o'; break;
                case 'Schedule': iconName = focused ? 'calendar' : 'calendar-o'; break;
                case 'Rankings': iconName = focused ? 'list-ol' : 'list-ul'; break;
                case 'Current Heat': iconName = focused ? 'fire' : 'fire'; break;
              }

              return <FontAwesome name={iconName} size={size} color={color} />;
            },
          })}
          tabBarOptions={{
            activeTintColor: '#FF883E',
            inactiveTintColor: '#169B62',
            animationEnabled: true
          }}
        >

          <Tab.Screen name="Me" component={UserStackScreen} />
          {user ? 
          <>
            <Tab.Screen name="Schedule" component={user.id ? ScheduleStackScreen : UserStackScreen} />
            <Tab.Screen name="Rankings" component={user.id ? ScoresStackScreen : UserStackScreen} />
            <Tab.Screen name="Current Heat" component={user.id ? HeatStackScreen : UserStackScreen} />
            </> : null
          }
          
        </Tab.Navigator>
      </NavigationContainer>
  );
}