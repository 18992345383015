import React from 'react';
import Main from './Main';
import { Provider } from './Context'

export default function App() {
  return (
    <Provider>
      <Main />
    </Provider>
  );
}