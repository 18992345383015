import * as React from 'react';
import { Text, SafeAreaView, Image } from 'react-native';

const Loading = (props) => {
    return (
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Image
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 216,
                    height: 216
                }}
                source={{
                uri: 'https://www.93octanebrewery.com/img/93octane/beer-fill.gif',
            }}
            />
            <Text>Loading ...</Text>
        </SafeAreaView>
    );
}
export default Loading;

