import React, { useContext, useState, useEffect} from 'react';
import { Text, View, SafeAreaView, StyleSheet, TouchableOpacity, ScrollView, StatusBar} from 'react-native';
import { Context } from '../Context';
import Loading from '../elements/Loading';
import { FontAwesome } from '@expo/vector-icons';
import { useIsFocused } from "@react-navigation/native";
import Markdown from 'react-native-easy-markdown';
import CountDown from 'react-native-countdown-component';
import { Audio } from 'expo-av';

const HeatScreen = (props) => {

    const [user] = useContext(Context);
    const [heat, setHeat] = useState(null);
    const [sound, setSound] = useState(null);
    const [timeEnd, setTimeEnd] = useState(null);
    const [secondsUntilEnd, setSecondsUntilEnd] = useState(0)

    useEffect(() => {

        const fetchData = async () => {

            let response = await fetch('http://seansusko.com/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getCurrentHeatDetails',
                    id: user.id
                })
            });

            const results = await response.json();
            setHeat(results.event);

            //check the countdown
            if(timeEnd !== results.event.heat_end){
                console.log('setting time end to ' + results.event.heat_end)
                setTimeEnd(results.event.heat_end);
            };

        };

        const interval = setInterval(() => {
            fetchData()
        }, 5000);

        return () => {
            clearInterval(interval);
            setHeat(null);
            setTimeEnd(null);
            setSecondsUntilEnd(0);
        }

    },[useIsFocused])

    useEffect(() => {
        if(timeEnd){
            const now = new Date().valueOf();
            const time = timeEnd.split(":");
            const end = new Date().setHours(time[0],time[1],time[2]);
            const secondsLeftInHeat = (end - now) / 1000;
            if(end > now){
                console.log(now);
                console.log(end);                
                console.log('Setting seconds left to' + secondsLeftInHeat);
                setSecondsUntilEnd(secondsLeftInHeat)
            } else {
                console.log('Setting seconds left to 0');
                setSecondsUntilEnd(0)
            }
        }
        
    }, [timeEnd]);

    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
           require('./klaxon.mp3')
        );
        setSound(sound);
        await sound.playAsync(); }
    
      useEffect(() => {
        return sound
          ? () => {
              sound.unloadAsync(); }
          : undefined;
      }, [sound]);

    const styles = StyleSheet.create({
        scrollView: {
            backgroundColor: 'white',
            padding:10,
            margin: 10
        },
        tasks:{
          width: '100%'
        },
        cardContent: {
          marginLeft:20,
          marginTop:10,
        },      
        card:{
          shadowColor: '#00000021',
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.37,
          shadowRadius: 7.49,
          elevation: 12,   
          minHeight: 120,   
          maxHeight: 150,
          marginVertical: 10,
          marginHorizontal:20,
          backgroundColor:"white",
          flexBasis: '26%',
          padding: 10,
          flexDirection:'row',
          flexWrap: 'wrap',
          borderLeftWidth:6,
          flex: 1,
          justifyContent: 'space-between',
          height: 200
        },      
        description:{
          fontSize:20,
          flex:1,
          color:"#008080",
          fontWeight:'bold',
        },
        teammate:{
          fontSize:18,
          flex:1,
          color:"#696969",
          marginTop:5
        },
      }); 

    let color = '#169B62';

    const onTimerEnd = () => {
        playSound()
    }

    return (
        !heat ? 
        <Loading />
        :
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

            <View style={{height: 60, marginBottom: 5 }}>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} >
                    
                        {secondsUntilEnd !== 0 ? 
                            <CountDown
                                id="counter"
                                until={secondsUntilEnd}
                                onFinish={() => onTimerEnd()}
                                size={22}
                                digitStyle={{backgroundColor: '#169B62'}}
                                digitTxtStyle={{color: '#FFF'}}
                                timeToShow={['H','M', 'S']}
                                timeLabels={{h: '', m: '', s: ''}}
                            /> : 
                            <Text style={{marginTop: 5, fontSize: 18, fontWeight: 'bold',color: color}}>
                                This event has ended.
                            </Text>
                        }
                   
                </View>                
            </View>
            
            <View>
                <TouchableOpacity style={[styles.card, {borderColor:color, marginTop: 5}] }  onPress={e => openEvent(heat)}>
                    <Text style={{fontSize: 18,color:"#FF883E"}}><FontAwesome name="fire" size={25} color="#FF883E" /> {heat.heat}</Text>
                
                    <View style={styles.cardContent}>
                        <Text style={[styles.description, { color: color}]}>{heat.event ? heat.event : 'Bye - Take a Break!'}</Text>
                        <Text style={[styles.teammate, { color: color}]}>{heat.event ? heat.teammate : null}</Text>
                        <Text style={[styles.teammate,{ color: color}]}>{heat.opponents ? 'vs. ' + heat.opponents[0].concat(' & ',heat.opponents[1]) : null }</Text>
                    </View>

                    <Text style={{fontSize: 18, fontWeight: 'bold',color: color}}> {heat.score}</Text>
                </TouchableOpacity>
            </View>

            <View style={{height: 180}}>
                <Text style={{fontSize: 18, fontWeight: 'bold',color: color}}> Rules </Text>
                
                <ScrollView contentInsetAdjustmentBehavior="automatic" style={styles.scrollView}>
                    <Markdown style={{fontSize: 18}}>{heat.rules}</Markdown>                    
                </ScrollView>
            </View>

            <View style={{height: 180}}>
                <Text style={{fontSize: 18, fontWeight: 'bold',color: color}}> Scoring </Text>
                <ScrollView contentInsetAdjustmentBehavior="automatic" style={styles.scrollView}>
                    <Markdown style={{text: {fontSize: 40}}}>{heat.scoring}</Markdown>                    
                </ScrollView>
            </View>
        </SafeAreaView>
    );
}
export default HeatScreen;
