import React, {useRef, useContext, useState } from 'react';
import { Text, SafeAreaView, TextInput, TouchableOpacity } from 'react-native';
import { Context } from '../Context';

const ScheduleDetailsScreen = (props) => {

    const event = props.route.params.event

    const [user] = useContext(Context);
    const [error, setError] = useState();
    const ref = useRef();

    const submitScore = () => {
        const fetchData = async (score) => {

            let response = await fetch('http://seansusko.com/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'updateScore',
                    id: user.id,
                    schedId: event.id,
                    score: score
                })
            });

            const results = await response.json();
            if(!results.success){
                setError(results.message);
            } else {
                props.navigation.navigate('Schedule');
            }
            
        };

        const score = ref.current.value;
        if(!score){
            setError("You must enter a valid number");
        } else {
            fetchData(score)
        }
        
    }


    return (
        event.event ? 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>            
            <Text style={{fontSize: 18}}>Enter Score For Heat {event.heat}</Text>
            <Text style={{fontSize: 18}}>{event.event} </Text>
            <Text style={{fontSize: 18}}>Partner: {event.teammate}</Text>
            <Text style={{ width: '80%', color: 'red', fontSize: 18, marginTop: 8}}>{ error }</Text>
            <TextInput 
                ref={ref} 
                style={{
                    fontSize: 17,
                    backgroundColor: 'white',
                    padding: 10,
                    marginBottom: 10,
                    marginTop: 40,
                    borderRadius: 10,
                    width: '15%',
                }}
                keyboardType="number-pad"
                returnKeyType="done"
                returnKeyLabel="done"
                autoCapitalize="none"
                maxLength={2}
            />
            <TouchableOpacity style={{
                marginTop:10,
                height:45,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom:20,
                width:250,
                borderRadius:30,
                color: 'white',
                backgroundColor: "#169B62",
            }} onPress={submitScore}>
                <Text style={{color: 'white'}}>Submit Score*</Text> 
            </TouchableOpacity>
            <Text style={{fontSize: 18}}> *Remember, God is watching you </Text>
        </SafeAreaView> :
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{fontSize: 18}}>No score recorded for a bye.</Text>
        </SafeAreaView>
    );
}
export default ScheduleDetailsScreen;