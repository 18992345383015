import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, View, TouchableOpacity, StyleSheet, FlatList, RefreshControl, Modal} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useIsFocused } from "@react-navigation/native";
import Loading from '../elements/Loading';

const Schedule = (props) => {

    const [user] = useContext(Context);
    const isFocused = useIsFocused();

    const [events, setEvents] = useState([]);
    const [score, setScore] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const fetchData = async () => {

        let response = await fetch('http://seansusko.com/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getEventInfo',
                id: user.id
            })
        });

        const results = await response.json();
        setScore(results.user.score);
        setEvents(results.user.events);
        setRefreshing(false);        
    };

    useEffect(() => {
        fetchData()
    }, [isFocused]);

    const refeshData = () => {
        setRefreshing(true);
        fetchData();
    };

    const openEvent = (event) => {
        props.navigation.navigate('Details', { event: event })
    }

    const renderEvent = (event) => {
        let color = event.item.current ? '#169B62' : 'grey' 
        return (

            <TouchableOpacity style={[styles.card, {borderColor:color}]}  onPress={e => openEvent(event.item)}>
                <Text style={{ fontSize: 18, color: event.item.current ? color : "#FF883E"}}>
                    <FontAwesome name="fire" size={25} color={event.item.current ? color : "#FF883E"} /> 
                    {event.item.heat}
                </Text>
              
                <View style={styles.cardContent}>
                    <Text style={[styles.description, { color: color}]}>{event.item.event ? event.item.event : 'Bye - Take a Break!'}</Text>
                    <Text style={[styles.teammate, { color: color}]}>{event.item.event ? event.item.teammate : null}</Text>
                    <Text style={[styles.teammate,{ color: color}]}>{event.item.opponents ? 'vs. ' + event.item.opponents[0].concat(' & ',event.item.opponents[1]) : null }</Text>
                </View>

                <Text style={{fontSize: 18, fontWeight: 'bold',color: color}}> {event.item.score}</Text>
            </TouchableOpacity>

        )
    }

    const styles = StyleSheet.create({
        tasks:{
          flex:1,
          width: '100%'
        },
        cardContent: {
          marginLeft:20,
          marginTop:10,
        },
        image:{
          width:25,
          height:25,
        },      
        card:{
          shadowColor: '#00000021',
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.37,
          shadowRadius: 7.49,
          elevation: 12,   
          minHeight: 120,   
          marginVertical: 10,
          marginHorizontal:20,
          backgroundColor:"white",
          flexBasis: '46%',
          padding: 10,
          flexDirection:'row',
          flexWrap: 'wrap',
          borderLeftWidth:6,
          flex: 1,
          justifyContent: 'space-between'
        },      
        description:{
          fontSize:20,
          flex:1,
          color:"#008080",
          fontWeight:'bold',
        },
        teammate:{
          fontSize:18,
          flex:1,
          color:"#696969",
          marginTop:5
        },
      }); 

    return (      
        !events.length ? 
        <Loading /> : 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>  
            <View style={{ height: 40}}>
                <Text style={{
                    fontSize:19,
                    color:"#151515",
                    fontWeight: 'bold'
                }}>
                    Total Pts: {score}
                    <TouchableOpacity onPress={refeshData} >
                        <FontAwesome name="refresh" size={30} color="#646464" style={{ marginLeft: 20, marginTop: 5}} />
                    </TouchableOpacity>
                </Text>                
            </View>    
            
            <FlatList 
                style={styles.tasks}
                data={events}
                keyExtractor= {(event) => event.id}
                renderItem={renderEvent}
                refreshControl={<RefreshControl refreshing={refreshing} onRefesh={refeshData} />}
            />
        </SafeAreaView>
    );
}
export default Schedule;